<template>
  <section class="dtc-main-section" id="" style="margin-bottom:60px;">
    <header
      class="dtc-title"
      style="font-size: 20px; display: grid; place-items: start;font-family:DFKai-sb;"
    >
      附件1
    </header>
    <header
      class="dtc-title"
      style="font-size: 20px; display: grid; place-items: center;font-family:DFKai-sb;"
    >
      <!-- {{ bYear }}學年度第{{term}}學期偏鄉護理菁英計畫補助學生學雜費、平安保險等費用預估概算審定總表({{applyYear}}年{{applyMonth}}月至{{approveYear}}年{{approveMonth}}月) -->
      {{ title }}
    </header>
    <header class="dtc-grid-header8 mt-0" style="background:white;">
      <div class="dtc-grid-height">序號</div>
      <div class="dtc-grid-height">校別</div>
      <div class="dtc-grid-height">學生人數</div>
      <div class="dtc-grid-height">年級</div>
      <section class="dtc-heaer-s1">
        <div>學雜費(含退輔基金、實驗費)</div>
        <div>學生平安保險費</div>
        <div>教學實習費(含電腦網路使用費)</div>
      </section>
      <section class="dtc-heaer-s2">
        <div>膳食費 3250元/月/*6個月</div>
        <div>零用津貼 3500元*6個月</div>

        <div>課業費 1,000元 /每學期</div>
        <div>書籍費 4,000元 /每學期</div>
        <div>制服費 2,500元 /每學期</div>
        <div>應屆畢業生旅行參訪費 (畢業當年度)3,000元/每學生</div>
      </section>
      <section class="dtc-heaer-s3">
        <div>返鄉旅費 (交通費核實支付) 5,000元 /每人每學期</div>
        <div>住宿費 3,000元/月/*6個月</div>
      </section>
      <div class="dtc-grid-height">合計</div>
      <div class="dtc-grid-height" style="border-right:0px">各校總計</div>
    </header>
    <main class="dtc-grid-main">
      <div :style="map.length < 2 ? 'padding-top:19px' : ''">
        {{ serialNo }}
      </div>
      <div :style="map.length < 2 ? 'padding-top:19px' : ''">
        {{ schoolName }}
      </div>
      <section class="dtc-main-sec">
        <div
          v-for="(item, idx) in map"
          :key="idx"
          :style="map.length < 2 ? 'padding-top:30px' : ''"
        >
          {{ item.NumberOfPeople }}
        </div>
      </section>
      <section class="dtc-main-sec">
        <div
          v-for="(item, idx) in map"
          :key="idx"
          :style="map.length < 2 ? 'padding-top:30px' : ''"
        >
          {{ item.GradeName }}
        </div>
      </section>
      <div class="dtc-main-sec">
        <div
          v-for="(item, idx) in map"
          :key="idx"
          class="text-right-mode"
          :style="map.length < 2 ? 'padding-top:30px' : ''"
        >
          {{ $formatPrice(item.Fee_1) }}
        </div>
      </div>
      <div class="dtc-main-sec">
        <div
          v-for="(item, idx) in map"
          :key="idx"
          class="text-right-mode"
          :style="map.length < 2 ? 'padding-top:30px' : ''"
        >
          {{ $formatPrice(item.Fee_2) }}
        </div>
      </div>
      <div class="dtc-main-sec">
        <div
          v-for="(item, idx) in map"
          :key="idx"
          class="text-right-mode"
          :style="map.length < 2 ? 'padding-top:30px' : ''"
        >
          {{ $formatPrice(item.Fee_3) }}
        </div>
      </div>
      <div class="dtc-main-sec">
        <div
          v-for="(item, idx) in map"
          :key="idx"
          class="text-right-mode"
          :style="map.length < 2 ? 'padding-top:30px' : ''"
        >
          {{ $formatPrice(item.Fee_4) }}
        </div>
      </div>
      <div class="dtc-main-sec">
        <div
          v-for="(item, idx) in map"
          :key="idx"
          class="text-right-mode"
          :style="map.length < 2 ? 'padding-top:30px' : ''"
        >
          {{ $formatPrice(item.Fee_5) }}
        </div>
      </div>
      <div class="dtc-main-sec">
        <div
          v-for="(item, idx) in map"
          :key="idx"
          class="text-right-mode"
          :style="map.length < 2 ? 'padding-top:30px' : ''"
        >
          {{ $formatPrice(item.Fee_6) }}
        </div>
      </div>
      <div class="dtc-main-sec">
        <div
          v-for="(item, idx) in map"
          :key="idx"
          class="text-right-mode"
          :style="map.length < 2 ? 'padding-top:30px' : ''"
        >
          {{ $formatPrice(item.Fee_7) }}
        </div>
      </div>
      <div class="dtc-main-sec">
        <div
          v-for="(item, idx) in map"
          :key="idx"
          class="text-right-mode"
          :style="map.length < 2 ? 'padding-top:30px' : ''"
        >
          {{ $formatPrice(item.Fee_8) }}
        </div>
      </div>
      <div class="dtc-main-sec">
        <div
          v-for="(item, idx) in map"
          :key="idx"
          class="text-right-mode"
          :style="map.length < 2 ? 'padding-top:30px' : ''"
        >
          {{ $formatPrice(item.Fee_9) }}
        </div>
      </div>
      <div class="dtc-main-sec">
        <div
          v-for="(item, idx) in map"
          :key="idx"
          class="text-right-mode"
          :style="map.length < 2 ? 'padding-top:30px' : ''"
        >
          {{ $formatPrice(item.Fee_10) }}
        </div>
      </div>
      <div class="dtc-main-sec">
        <div
          v-for="(item, idx) in map"
          :key="idx"
          class="text-right-mode"
          :style="map.length < 2 ? 'padding-top:30px' : ''"
        >
          {{ $formatPrice(item.Fee_11) }}
        </div>
      </div>
      <div class="dtc-main-sec">
        <div
          v-for="(item, idx) in map"
          :key="idx"
          class="text-right-mode"
          :style="map.length < 2 ? 'padding-top:30px' : ''"
        >
          {{ $formatPrice(item.Subtotal) }}
        </div>
      </div>
      <div :style="map.length < 2 ? 'padding-top:19px' : ''">
        {{ $formatPrice(total) }}
      </div>

      <!-- <div></div>
      <div></div>-->
    </main>
    <!-- <footer class="dtc-note">
      <div>備註</div>
      <div>1.一年級係指107學年入學公費生。</div>
      <div>2.二年級係指106學年度入學公費生。</div>
      <div>3.三年級係指105學年度入學公費生。</div>
      <div>4.四年級係指104學年度入學公費生</div>
      <div>
        5.國立成功大學、國立陽明大學、中國醫藥大學、義守大學、國立台中科技大學及慈濟科技大學104學年無招生學生。
      </div>
      <div>6.國立陽明大學、中國醫藥大學及義守大學105學年度無招生學生。</div>
      <div>
        7.國立成功大學、義守大學、國立台北護理健康大學、長庚科技大學及弘光科技大學106學年度無招生學生。
      </div>
    </footer> -->
  </section>
</template>

<script>
import queryString from "query-string";
const levels = ["一", "二", "三", "四"];
const rowObj = {};
let pageRows = new Array(100).fill().map((s, t) => {
  return {
    twYears,
    term: 1,
    map: "",
    myKey: t,
    s1: "",
    s2: "",
    s3: "",
    s4: "",
    cat3_1: "",
    cat3_2: "",
    cat3_3: "",
    cat6_1: "",
    cat6_2: "",
    cat6_3: "",
    cat6_4: "",
    cat6_5: "",
    cat6_6: "",
    cat2_1: "",
    cat2_2: "",
    catTotal: "",
    totalAmount: "",
  };
});

export default {
  name: "doc2",
  data() {
    return {
      term: 1,
      bYear: "",
      schoolCode: "",
      category: "",
      totalList: [],
      schoolArr: [],
      schools: [],
      levels,
      pageRows,
      pageSize: 22,
      isHeaderVisible: true,
      title: "",
      allData: [],
      map: [],
      serialNo: "",
      schoolName: "",
      total: 0,
    };
  },

  methods: {
    save() {
      this.$root.$emit("dtc-save-form-ok");
      if (!window.location.href.includes("doc8-1")) return;
      this.deNormalizeData();
    },
    updateRow(item) {
      const n = +item.s1 + +item.s2 + +item.s3 + +item.s4;
      item.catTotal = "";
      if (n) {
        item.cat3_1 = n * 64803;
        item.cat3_2 = n * 270;
        item.cat3_3 = n * 1106;
        item.cat6_1 = n * 3250 * 6;
        item.cat6_2 = n * 3500 * 6;
        item.cat6_3 = n * 1000;
        item.cat6_4 = n * 4000;
        item.cat6_5 = n * 2500;
        // item.cat6_6 = n * 3000;
        // item.cat2_1 = n * 5000;
        // item.cat2_2 = n * 3000 * 6;
        let total = item.cat3_1 + item.cat3_2 + item.cat3_3;
        total +=
          item.cat6_2 +
          item.cat6_3 +
          item.cat6_4 +
          item.cat6_5 +
          item.cat6_6 +
          item.cat6_1;
        item.catTotal = total + item.cat2_1 + item.cat2_2;
      }
    },
    deNormalizeData() {
      this.map.forEach((s, idx) => {
        s.budgetings.forEach((b) => {
          b.Grade == window.level4[0]
            ? (b.NumberOfPeople = this.totalList[idx].s1)
            : "";
          b.Grade == window.level4[1]
            ? (b.NumberOfPeople = this.totalList[idx].s2)
            : "";
          b.Grade == window.level4[2]
            ? (b.NumberOfPeople = this.totalList[idx].s3)
            : "";
          b.Grade == window.level4[3]
            ? (b.NumberOfPeople = this.totalList[idx].s4)
            : "";
        });
      });
    },
    normalizeData(map) {
      const items = [];
      // step1: fill each school name into items
      this.map.forEach((s) => {
        item.s1 = item.s2 = item.s3 = item.s4 = 0;
        item.cat6_6 = 0;
        item.cat2_1 = 0;
        item.cat2_2 = 0;
        item.totalAmount = 0;
        item.SeqNo = s.SerialNo;
        s.budgetings.forEach((k, i) => {
          k.Grade == window.level4[0] ? (item.s1 = k.NumberOfPeople) : "";
          k.Grade == window.level4[1] ? (item.s2 = k.NumberOfPeople) : "";
          k.Grade == window.level4[2] ? (item.s3 = k.NumberOfPeople) : "";
          k.Grade == window.level4[3] ? (item.s4 = k.NumberOfPeople) : "";
          if (k.Grade == window.level4[1]) {
            item.L2 = { ...k };
          } else if (k.Grade == window.level4[2]) {
            item.L3 = { ...k };
          } else if (k.Grade == window.level4[3]) {
            item.L4 = { ...k };
          }

          item.totalAmount += k.Subtotal;
          item.cat6_6 += k.Fee_9;
          item.cat2_1 += k.Fee_10;
          item.cat2_2 += k.Fee_11;
        });

        items.push(item);
      });
      this.totalList = items;

      items.forEach((s) => this.updateRow(s));
    },
    async getData() {
      this.totalList = [];
      const url = `Budgeting/GetBudgetingPri?schoolcode=${this.schoolCode}&byear=${this.bYear}&term=${this.term}&category=${this.category}`;
      try {
        const map = await window.axios.get(url);

        if (map) {
          this.map = map[0].budgeting_head[0].budgetings;
          this.allData = map;
          this.title = map[0].Title;
          this.schoolName = map[0].budgeting_head[0].SchoolName;
          this.serialNo = map[0].budgeting_head[0].SerialNo;
          this.map.forEach((s, i) => {
            this.total += s.Subtotal;
          });
        }
      } catch (e) {
        const str = e + " API: " + url;
        this.$root.$emit("dtc-server-error", str);
      }
    },
  },
  async mounted() {
    let schools = [];
    if (window.dtcSchools) {
      this.schoolArr = schools = window.dtcSchools;
    } else {
      this.schoolArr = schools = await window.axios.get("School/GetAll");
    }
    const {
      schoolcode,
      byear: Byear = "110",
      term = "1",
      category = "N",
    } = queryString.parse(location.href.split("?")[1]);
    this.term = +term;
    this.schoolCode = schoolcode;
    this.category = category;
    Byear ? (this.bYear = Byear) : (this.bYear = "110");

    //for check is same school-prevent see other school
    const loginHealthCode = sessionStorage.getItem("darcok").replace(/\"/g, "");

    const stuChoolArr = JSON.parse(sessionStorage.getItem("stuChool"));
    const roleOtalArr = JSON.parse(sessionStorage.getItem("roleOtal"));
    const isSchoolPerson = stuChoolArr.includes(loginHealthCode) ? true : false;

    const isAdminPerson = roleOtalArr.includes(loginHealthCode) ? true : false;

    if (!isSchoolPerson && !isAdminPerson) {
      return;
    }

    if (isSchoolPerson) {
      if (loginHealthCode != schoolcode) {
        return;
      }
    }
    //check Can see and then get data
    this.getData();
  },
  beforeMount() {
    // const {
    //   schoolcode: SchoolCode = "0003",
    //   byear: Byear = "110",
    //   term = "1",
    //   category: Category = "N",
    // } = queryString.parse(location.href.split("?")[1]);
    // this.term = +term;
    // Byear ? (this.bYear = Byear) : (this.bYear = "110");
  },
  beforeCreate() {
    let year = new Date()
      .toISOString()
      .split("T")[0]
      .split("-")[0];
    year = Number(year);
    const arr = [];
    for (let i = 0; i < 7; ++i) {
      arr.push(year - 1911);
      --year;
    }
    this.twYears = arr;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dtc-main-section {
  position: relative;
  --dtc-grid-header-height: 190px;
  --dtc-doc8-width: 1290px;
  --dtc-border-color: var(--dark);
  margin: 30px auto;
  min-height: 120px;
  max-width: var(--dtc-doc8-width);
  font-size: 14px;
  text-align: center;
}
.dtc-grid-height {
  line-height: var(--dtc-grid-header-height);
}

.dtc-grid-header8 {
  position: sticky;
  //background: var(--dark);
  top: 0px;
  display: grid;
  border: 1px solid var(--dtc-border-color);
  grid-template-columns: 36px 135px 58px 40px 270px 420px 160px 86px 94px;
  > div {
    height: var(--dtc-grid-header-height);
    border-right: 1px solid var(--dtc-border-color);
    text-align: center;
  }
  section {
    display: grid;
    position: relative;
    align-items: end;
    border-right: 1px solid var(--dtc-border-color);
    &::before,
    &::after {
      position: absolute;
      content: "實報實銷類";
      display: block;
      transform: translateX(-50%);
      width: max-content;
      height: 30px;
      font-size: 16px;
      top: 10%;
      left: 50%;
      right: 0;
      bottom: 0;
    }
    > div {
      max-height: 140px;
      padding-top: 2px;
      height: var(--dtc-grid-header-height);
      border-right: 1px solid var(--dtc-border-color);
      border-top: 1px solid var(--dtc-border-color);
    }
    > div:last-child {
      border-right: none;
    }
  }
  .dtc-heaer-s1 {
    grid-template-columns: repeat(3, 90px);
    > div {
      //margin-right: -1px;
    }
    &::before {
      content: "實報實銷類";
    }
    &::after {
      content: "";
    }
  }
  .dtc-heaer-s2 {
    &::before {
      content: "定額核銷類";
    }
    &::after {
      content: "";
    }
    grid-template-columns: repeat(6, 70px);
    > div {
      // margin-right: -1px;
    }
  }
  .dtc-heaer-s3 {
    &::before {
      content: "在補助額度內檢";
      top: 2%;
    }
    &::after {
      content: "附單據或證明類";
      top: 15%;
    }
    grid-template-columns: repeat(2, 80px);
    > div {
      //margin-right: -1px;
    }
  }
}

.dtc-note {
  grid-column: 1/-1;
  text-align: left;
  display: grid;
  border: 1px solid var(--dtc-border-color);
  border-top: none;
  height: 178px;
  grid-template-columns: 1fr;
  padding: 7px;
  > div:not(:first-child) {
    padding-left: 9px;
  }
}

.dtc-grid-main {
  display: grid;
  --height: 98px;
  border: 1px solid var(--dtc-border-color);
  border-top: none;
  height: var(--height);
  // grid-template-columns: 36px 132px 51px 40px 360px 450px 180px 90px;
  font-size: 16px;
  grid-template-columns:
    36px 135px 58px 40px repeat(3, 90px) repeat(6, 70px) repeat(2, 80px)
    86px 94px;
  > div {
    border-right: 1px solid var(--dtc-border-color);
    line-height: 46px;
  }
  > div:last-child {
    border-right: none;
  }
  .dtc-main-sec {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
    border-right: 1px solid var(--dtc-border-color);
    > div {
      height: 26px;
      line-height: 26px;
      border-bottom: 1px solid var(--dtc-border-color);
    }
    > div:last-child {
      border-bottom: none;
    }
  }
}
.dtc-title {
  display: inline-block;
  margin: auto;
  font-size: 16px;
  width: 1290px;
}

.input-group-prepend .input-group-text {
  min-width: 150px;
}

.dtc-red-dot {
  position: relative;
  &::before {
    position: absolute;
    display: block;
    width: 20px;
    height: 20px;
    top: -10px;
    left: 12px;
    content: "*";
    color: red;
    font-size: 24px;
  }
}

@media print {
  #app .input-group-prepend .input-group-text {
    background: white;
    color: black;
  }
  #app .input-group-append .input-group-text {
    background: white;
    color: black;
  }
  #app {
    .input-group input,
    .input-group-text input,
    input,
    textarea,
    select,
    textarea:focus .input-group select {
      background: white;
      color: black;
    }
  }
}
.text-right-mode {
  text-align: right;
  padding-right: 5px;
}
@media print {
  .dtc-main-section {
    --dtc-border-color: var(--dark) !important;
    width: var(--dtc-doc8-width);
    position: relative;
    //margin: 3px auto 60px auto;
    width: var(--dtc-doc8-width);

    color: black !important;
    // max-width: 100vw;
  }
  @page {
    size: A4 landscape;
  }
}
</style>
